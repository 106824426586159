<template>
<div v-if="isShow" class="show-spinner">
  <CSpinner color="danger" class="loader"></CSpinner>
  <div></div>
</div>  
</template>

<script>
export default {
  name: "Spinner",
  props:['isShow']
};
</script>
<style scoped>
.loader {
  position: fixed;
  top: 45%;
  right: 45%;
  z-index: 1070;
  width:5rem;
  height:5rem;
}
.show-spinner{
  position: fixed; /* Stay in place */
  z-index: 1060; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(56, 56, 56); /* Fallback color */
  background-color: rgba(219, 219, 219, 0.349); /* Black w/ opacity */
}
</style>
